/* eslint-disable react-hooks/exhaustive-deps */
import { Excel } from "antd-table-saveas-excel";
import Table, { ColumnsType } from "antd/lib/table";
import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { isMobile } from "react-device-detect";
import { CalculationFooter } from "../../components/calculation-footer/CalculationFooter";
import { defaultFormatDate } from "../../config/constants";
import { translations } from "../../config/translations";
import { BaseViewModel } from "../../core/models/Base";
import { PersonType } from "../../core/models/Enum";
import { PayOffBaseVM, PayoffFilter, PayoffVM } from "../../core/models/Payoff";
import { PersonBaseVM } from "../../core/models/Person";
import { getHourlyRateDetails } from "../../core/services/HourlyRateService";
import { getPayoffsList } from "../../core/services/PayoffService";
import { useAuthContext } from "../../helpers/AuthContext";
import { convertValueForExcelExport } from "../../helpers/NumberHelper";
import {
  getDefaultHourlyRateFromPersonType,
  getHourlyRateConfigKeyFromPersonType,
  isAdmin
} from "../../helpers/PersonHelper";
import { sortByTextValue } from "../../helpers/SortHelper";
import {
  convertUTCToLocalFormattedTime,
  genericPaginationProps,
  genericTableProps
} from "../../helpers/TableHelper";

interface Props {
  filter?: PayoffFilter;
}

const PayoffsTable: React.FC<Props & { ref: ForwardedRef<any> }> = forwardRef(
  (props: Props, ref: ForwardedRef<any>) => {
    const { filter } = props;
    const { language, userRole, userPersonId, setLoading } = useAuthContext();
    const authContext = useAuthContext();
    const [tableData, setTableData] = useState<PayoffVM[]>([]);
    const [hourlyRate, setHourlyRate] = useState<number | undefined>(undefined);

    useImperativeHandle(ref, () => ({
      getTableData: (fetchFilter?: PayoffFilter) => {
        getTableData(fetchFilter);
      },
      downloadXlsx: () => {
        downloadXlsx();
      },
      downloadGroupedXlsx: () => {
        downloadGroupedXlsx();
      },
      clearData: () => {
        setTableData([]);
      },
    }));

    useEffect(() => {
      getHourlyRate();
    }, [filter?.personType]);

    const getTableData = async (fetchFilter?: PayoffFilter) => {
      if (!fetchFilter) return;

      setLoading(true);
      const data = await getPayoffsList(
        authContext,
        !isAdmin(userRole) && userPersonId
          ? { ...fetchFilter, personId: userPersonId }
          : fetchFilter
      );

      if (data) {
        setTableData(data);
      }

      setLoading(false);
    };

    const getHourlyRate = async () => {
      const data = await getHourlyRateDetails(
        authContext,
        getHourlyRateConfigKeyFromPersonType(filter?.personType)
      );

      setHourlyRate(
        data?.value
          ? (data?.value as number)
          : getDefaultHourlyRateFromPersonType(filter?.personType)
      );
    };

    const downloadXlsx = async (): Promise<void> => {
      const excel = new Excel();
      excel
        .addSheet(translations[language].report)
        .addColumns(getColumns(false) as any)
        .addDataSource(tableData, {
          str2Percent: true,
        })
        .saveAs("Payoffs.xlsx");
    };

    const downloadGroupedXlsx = async (): Promise<void> => {
      const mappedTableData = tableData.map(
        (row: PayoffVM): PayOffBaseVM => ({
          id: row.person.id,
          name: `${row.person.firstName} ${row.person.lastName}`,
          representative: row.person.representative
            ? row.person.representative.name
            : "",
          fee:
            filter?.personType === PersonType.Hostess
              ? row.fee
              : row.totalCost || 0,
        })
      );
      const groupedByIdTableData = mappedTableData.reduce(
        (result: any, currentValue) => {
          (result[currentValue["id"]] = result[currentValue["id"]] || []).push(
            currentValue
          );
          return result;
        },
        {}
      );

      const finalTableData: PayOffBaseVM[] = [];

      Object.keys(groupedByIdTableData).forEach((key: string) => {
        const sumOfFees = groupedByIdTableData[key].reduce(
          (sum: number, currentValue: any) => {
            return sum + currentValue.fee;
          },
          0
        );

        if (groupedByIdTableData[key][0]) {
          finalTableData.push({
            id: groupedByIdTableData[key][0].id,
            name: groupedByIdTableData[key][0].name,
            representative: groupedByIdTableData[key][0].representative,
            fee: sumOfFees,
          });
        }
      });

      const sortedFinalTableData = finalTableData.sort(sortByTextValue);

      const excel = new Excel();
      excel
        .addSheet(translations[language].report)
        .addColumns(getSpecialExcelColumns() as any)
        .addDataSource(sortedFinalTableData, {
          str2Percent: true,
        })
        .saveAs("Payoffs.xlsx");
    };

    const getSpecialExcelColumns = (): ColumnsType<PayoffVM> => {
      return [
        {
          title: translations[language].representative,
          dataIndex: "representative",
          render: (value?: string) => value,
        },
        {
          title: translations[language].fullName,
          dataIndex: "name",
          render: (value?: string) => value,
        },
        {
          title: `${translations[language].inTotal} (€)`,
          dataIndex: "fee",
          render: (value?: number) => convertValueForExcelExport(value),
        },
        {
          title: `${translations[language].numberOfHours} (h)`,
          dataIndex: "fee",
          render: (value?: number) =>
            value && hourlyRate ? Number((value / hourlyRate).toFixed(2)) : 0.0,
        },
      ];
    };

    const getColumns = (mobile: boolean): ColumnsType<PayoffVM> => {
      if (mobile) {
        const mobileColumns: ColumnsType<PayoffVM> = [
          {
            title: translations[language].promotionDate,
            dataIndex: "timeOfPromotion",
            render: (value: Date) =>
              convertUTCToLocalFormattedTime(value, defaultFormatDate),
          },
          {
            title: translations[language].location,
            dataIndex: "location",
            render: (value: BaseViewModel) => value.name,
          },
        ];

        if (filter?.personType !== PersonType.Hostess) {
          mobileColumns.push({
            title: `${translations[language].inTotal} (€)`,
            dataIndex: "totalCost",
            width: 120,
            render: (value?: number) => convertValueForExcelExport(value),
          });
        } else {
          mobileColumns.push({
            title: `${translations[language].inTotal} (€)`,
            dataIndex: "fee",
            width: 120,
            render: (value?: number) => convertValueForExcelExport(value),
          });
        }
        return mobileColumns;
      } else {
        const columns: ColumnsType<PayoffVM> = [
          {
            title: translations[language].promotionDate,
            dataIndex: "timeOfPromotion",
            render: (value: Date) =>
              convertUTCToLocalFormattedTime(value, defaultFormatDate),
          },
          {
            title: translations[language].fullName,
            dataIndex:
              filter?.personType !== PersonType.Promoter
                ? "person"
                : "promoter",
            render: (value: PersonBaseVM) =>
              value ? `${value.firstName} ${value.lastName}` : "",
          },
          {
            title: translations[language].city,
            dataIndex: "city",
            render: (value: BaseViewModel) => value.name,
          },
          {
            title: translations[language].location,
            dataIndex: "location",
            render: (value: BaseViewModel) => value.name,
          },
          {
            title: translations[language].brand,
            dataIndex: "brand",
            render: (value: BaseViewModel) => value.name,
          },
          {
            title: translations[language].promotionType,
            dataIndex: "promotionType",
            render: (value: BaseViewModel) => value.name,
          },
          {
            title: translations[language].promoter,
            dataIndex: "promoter",
            render: (value: PersonBaseVM) =>
              value ? `${value.firstName} ${value.lastName}` : "",
          },
          {
            title: `${translations[language].fee} (€)`,
            dataIndex: "fee",
            width: 120,
            render: (value?: number) => convertValueForExcelExport(value),
          },
        ];

        if (filter?.personType !== PersonType.Hostess) {
          columns.splice(
            8,
            0,
            {
              title: `${translations[language].travelFree} (€)`,
              dataIndex: "travelCost",
              width: 120,
              render: (value?: number) => convertValueForExcelExport(value),
            },
            {
              title: `${translations[language].tolls} (€)`,
              dataIndex: "tollsCost",
              width: 120,
              render: (value?: number) => convertValueForExcelExport(value),
            },
            {
              title: `${translations[language].parking} (€)`,
              dataIndex: "parkingCost",
              width: 120,
              render: (value?: number) => convertValueForExcelExport(value),
            },
            {
              title: `${translations[language].overnight} (€)`,
              dataIndex: "accomodationCost",
              width: 120,
              render: (value?: number) => convertValueForExcelExport(value),
            },
            {
              title: `${translations[language].rest} (€)`,
              dataIndex: "otherExpensesCost",
              width: 120,
              render: (value?: number) => convertValueForExcelExport(value),
            },
            {
              title: `${translations[language].inTotal} (€)`,
              dataIndex: "totalCost",
              width: 120,
              render: (value?: number) => convertValueForExcelExport(value),
            }
          );
          if (filter?.personType === PersonType.Promoter) {
            columns.splice(6, 1);
          }
        }

        return columns;
      }
    };

    return (
      <Table
        {...genericTableProps(language)}
        columns={getColumns(isMobile)}
        className="payoffTable"
        size="small"
        dataSource={tableData}
        rowKey={(item): string =>
          `${item.id}-${item.timeOfPromotion}-${
            item.person ? item.person.id : ""
          }-${item.location ? item.location.id : ""}`
        }
        footer={() => (
          <CalculationFooter rows={tableData} personType={filter?.personType} />
        )}
        pagination={{
          ...genericPaginationProps(language),
          defaultPageSize: 25,
        }}
      />
    );
  }
);

export default PayoffsTable;
